import axios from 'axios';

function LoginService() {
  const handleError = response => {
    let message = (response.data && response.data.message) ? response.data.message : response.data;

    if (response.status === 452) {
      message = 'System Error';
    }
    if (response.status === 452 && response.data && response.data.cause === 'Change password failed - INVALID/WEAK PASSWORD CHOICE') {
      message = 'Unable to update the Password. The value does not meet Password requirements.';
    }

    return Promise.reject(message);
  };

  const login = ({username, password, x500Id, appId}) => {
    const data = {
      username,
      password,
      x500Id
    };

    if (appId) {
      data.applicationId = appId;
    }

    return axios.post('/api/fs/token/v6/create', data)
    .then(response => sessionStorage.setItem('ngStorage-tokenData', JSON.stringify(response.data)))
    .catch(error => handleError(error.response));
  };

  const changePassword = ({username, password, x500Id, newPassword}) => {
    if (newPassword.length !== 8) {
      return Promise.reject('Password must be 8 characters');
    }

    return axios.post('/api/fs/token/v6/changePassword', {
      common: {
        x500id: x500Id.toUpperCase(),
        user: username.toUpperCase()
      },
      username: username.toUpperCase(),
      password: password.toUpperCase(),
      x500Id: x500Id.toUpperCase(),
      newPassword: newPassword.toUpperCase()
    })
    .catch(error => handleError(error.response));
  };

  return { login, changePassword };
}

export default LoginService();
