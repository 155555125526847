import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { Alert } from 'react-bootstrap';
import { useState, useLayoutEffect } from 'react';

function AlertBanner({ alerts }) {
  const [allAlerts, setAllAlerts] = useState(alerts || []);

  useLayoutEffect(() => {
    setAllAlerts(alerts || []);
  }, [alerts]);

  const removeAlert = index => {
    let newAlerts = [...allAlerts];
    newAlerts.splice(index, 1);
    setAllAlerts(newAlerts);
  };

  return (
    <div id="alert-div" className="alert-area" data-testid="alertDiv">
      {allAlerts.map((alert, index) => (
        <Alert key={index} variant={alert.variant} onClose={() => removeAlert(index)} dismissible>
          {alert.text}
        </Alert>
      ))}
    </div>
  );
}

export default AlertBanner;
