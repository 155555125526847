import 'bootstrap/dist/css/bootstrap.min.css';
import '../App.css';
import { Row, Col } from 'react-bootstrap';
import { useState, useLayoutEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AlertBanner from '../AlertBanner/AlertBanner';
import LoginService from './LoginService';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function LoginForm() {
  const history = useHistory();
  const query = useQuery();
  const userNameEl = useRef(null);
  const passwordEl = useRef(null);
  const [userName, setUserName] = useState('');
  const [authenticator, setauthenticator] = useState('');
  const [newAuthenticator, setnewAuthenticator] = useState('');
  const [verifyAuthenticator, setverifyAuthenticator] = useState('');
  const [x500id, setX500id] = useState(query.get('x500Id') || '');
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [changePasswordScreen, setChangePasswordScreen] = useState(false);

  useLayoutEffect(() => {
    setnewAuthenticator('');
    setverifyAuthenticator('');
    userNameEl.current.focus();
  }, [changePasswordScreen]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (changePasswordScreen) {
      handlePasswordChange();
    } else {
      handleLogin();
    }
  };

  const handlePasswordChange = () => {
    if (passwordsDoNotMatch()) {
      setnewAuthenticator('');
      setverifyAuthenticator('');
      setauthenticator('');
      passwordEl.current.focus();
    } else {
      setLoading(true);
      LoginService.changePassword({username: userName, password: authenticator, x500Id: x500id, newPassword: newAuthenticator})
      .then(() => addAlert({variant: 'success', text: 'Successfully changed password.'}))
      .catch(error => addAlert({variant: 'danger', text: String(error)}))
      .finally(() => setLoading(false));
    }
  };

  const handleLogin = () => {
    let appId = query.get('appId') || '';
    appId = appId.replace(/\W/g, '');

    setLoading(true);

    LoginService.login({username: userName, password: authenticator, x500Id: x500id, appId: appId})
    .then(() => {
      if (appId) {
        history.push(`/${appId}/`);
      } else {
        return new Promise((resolve, reject) => reject('No App ID specified!'));
      }
    })
    .catch(error => addAlert({variant: 'danger', text: String(error)}))
    .finally(() => setLoading(false));
  };

  const addAlert = alert => setAlerts([...alerts, alert]);
  const passwordsDoNotMatch = () => newAuthenticator !== verifyAuthenticator && newAuthenticator && verifyAuthenticator;
  const handleInput = (value, setFunc) => {
    setAlerts([]);
    setFunc(value);
  };

  return (
    <div>
      <h1 className="text-center">{changePasswordScreen ? 'Change Password' : 'Sign-on'}</h1>
      <AlertBanner alerts={alerts}></AlertBanner>
      <form name="loginForm" autoComplete="off">
        <div className="form-group form-group-lg">
          <Row>
            <Col xs={{ offset: 1, span: 10 }} sm={{ offset: 2, span: 8 }}>
              <p className={changePasswordScreen ? 'change-password-paragraph' : 'no-change-password'}>
                To change your Password, fill in the fields below.
              </p>

              <input name="username"
                ref={userNameEl}
                type="text"
                value={userName}
                required={true}
                autoComplete="off"
                className={`form-control emessenger-input ${submitted ? 'border-error' : ''}`}
                placeholder="Username"
                disabled={loading}
                onChange={(e) => handleInput(e.target.value, setUserName)}/>

              <input name="authenticator"
                ref={passwordEl}
                type="password"
                value={authenticator}
                required={true}
                autoComplete="off"
                className={`form-control emessenger-input ${submitted ? 'border-error' : ''}`}
                placeholder="Password"
                disabled={loading}
                onChange={(e) => handleInput(e.target.value, setauthenticator)}/>

              <input name="x500id"
                type="text"
                value={x500id}
                required={true}
                autoComplete="off"
                className={`form-control emessenger-input ${submitted ? 'border-error' : ''}`}
                placeholder="X500 ID"
                disabled={loading}
                onChange={(e) => handleInput(e.target.value, setX500id)}/>

              <div className={changePasswordScreen ? 'change-password' : 'no-change-password'}>
                <br/>

                <input name="newAuthenticator"
                  type="password"
                  value={newAuthenticator}
                  autoComplete="off"
                  required={changePasswordScreen}
                  className={`form-control emessenger-input ${submitted ? 'border-error' : ''}`}
                  placeholder="Type a new Password"
                  onChange={(e) => handleInput(e.target.value, setnewAuthenticator)}
                  disabled={loading}
                  maxLength="8"/>

                <input id="verifyAuthenticator"
                  name="verifyAuthenticator"
                  type="password"
                  value={verifyAuthenticator}
                  onChange={(e) => handleInput(e.target.value, setverifyAuthenticator)}
                  className={`form-control emessenger-input ${passwordsDoNotMatch() ? 'mismatch-password-border-error' : ''} ${submitted ? 'border-error' : ''}`}
                  autoComplete="off"
                  required={changePasswordScreen}
                  placeholder="Type your new Password again"
                  disabled={loading}
                  maxLength="8"/>

                {passwordsDoNotMatch() && <small>Your verify Password doesn't match your new Password.</small>}
              </div>

              {!loading &&
              <div>
                <Row>
                  <Col xs={{ offset: 2, span: 8 }} className="text-center">
                    <button type="submit"
                      onClick={(e) => handleSubmit(e)}
                      disabled={loading}
                      className="btn btn-default btn-block">
                      {changePasswordScreen ? 'Update Password' : 'Sign In'}
                    </button>
                  </Col>
                </Row>

                <Row>
                  <Col xs={{ offset: 2, span: 8 }} className="text-center">
                    <button type="button"
                      onClick={() => setChangePasswordScreen(!changePasswordScreen)}
                      disabled={loading}
                      className="btn btn-default btn-secondary btn-block">
                      {changePasswordScreen ? 'Back' : 'Change Password'}
                    </button>
                  </Col>
                </Row>
              </div>}

              {loading &&
              <div>
                <h3 className="text-center">Loading...</h3>
              </div>}
            </Col>
          </Row>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
